import appBumperModule from "../appBumperModule";
import { DeviceMeta, EnvironmentUrls } from "Roblox";

function appBumperService(appBumperConstants, httpService, localStorageService, urlService) {
    "ngInject";

    function getMetaData() {
        let os;
        let downloadLink;

        if (DeviceMeta().isIosDevice) {
            downloadLink = EnvironmentUrls.appStoreLink;
            os = appBumperConstants.osTypes.ios;
        } else {
            downloadLink = EnvironmentUrls.googlePlayStoreLink;
            os = appBumperConstants.osTypes.android;
        }

        return {
            appDownloadLink: downloadLink,
            osType: os,
            bumperDismissTimeMs: appBumperConstants.dismissTimeMs
        };
    }

    function logBumperClosed() {
        var curTime = new Date();
        localStorageService.setLocalStorage(appBumperConstants.localStorage.storeKey, { "timeInMs": curTime.valueOf() });
    }

    function shouldShowBumper(dismissTime) {
        var storageData = localStorageService.getLocalStorage(appBumperConstants.localStorage.storeKey);
        if (storageData && storageData.timeInMs) {
            var pastTime = new Date(storageData.timeInMs);
            var curTime = new Date();
            var diff = curTime.valueOf() - pastTime.valueOf();
            //see if we have exceeded the hide time.
            var hideTime = dismissTime || (1000 * 3600 * 24); //one day by default
            return Math.floor(diff / hideTime) >= 1;
        }
        return true;
    }

    return {
        getMetaData: getMetaData,
        logBumperClosed: logBumperClosed,
        shouldShowBumper: shouldShowBumper
    };
}

appBumperModule.factory("appBumperService", appBumperService);

export default appBumperService;