import { TranslationResourceProvider } from 'Roblox';
import angular from 'angular';

const appBumper = angular
    .module("appBumper", ["robloxApp", "modal", "appBumperAppTemplates"])
    .config(['$compileProvider', 'languageResourceProvider', function ($compileProvider, languageResourceProvider) {
        //need to whitelist "intent:" url for android.
        $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|intent|robloxmobile):/);

        const translationProvider = new TranslationResourceProvider();
        var downloadAppResources = translationProvider.getTranslationResource('Feature.DownloadApp');

        languageResourceProvider.setTranslationResources([downloadAppResources]);
    }]);

export default appBumper;