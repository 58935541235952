import angular from 'angular';
import { ExperimentationService } from 'Roblox';
import { importFilesUnderPath, templateCacheGenerator } from 'roblox-es6-migration-helper';
import appBumperConstants from './constants/appBumperConstants';

import '../../../css/appBumper/appBumper.scss';

// import main module definition.
import appBumperModule from './appBumperModule';

const PAYMENT_FLOW_URL_PATHS = [
  appBumperConstants.upgradeRobuxUrlPath,
  appBumperConstants.upgradePaymentMethodsUrlPath,
  appBumperConstants.upgradeRedeemUrlPath
];

importFilesUnderPath(require.context('./constants/', true, /\.js$/));
importFilesUnderPath(require.context('./services/', true, /\.js$/));
importFilesUnderPath(require.context('./controllers/', true, /\.js$/));
importFilesUnderPath(require.context('./directives/', true, /\.js$/));

const templateContext = require.context('./', true, /\.html$/);
const templates = templateCacheGenerator(angular, 'appBumperAppTemplates', templateContext);

// self manual initialization
const containerElement = document.getElementById('app-bumper-main');
containerElement.setAttribute('app-bumper', '');

const bootstrapFn = () => {
  angular.element(() => {
    angular.bootstrap(containerElement, [appBumperModule.name, templates.name]);
  });
};

const isInPaymentFlow = PAYMENT_FLOW_URL_PATHS.some(path => window.location.href.includes(path));

if (isInPaymentFlow && ExperimentationService?.getAllValuesForLayer) {
  ExperimentationService.getAllValuesForLayer(appBumperConstants.mobileExperimentLayer).then(
    ixpResult => {
      const landingPageType = ixpResult.LandingPageType;
      switch (landingPageType) {
        case appBumperConstants.robuxUpsellExperimentVariants.variantA:
        case appBumperConstants.robuxUpsellExperimentVariants.variantB:
          return;
        case appBumperConstants.robuxUpsellExperimentVariants.control:
        default:
          bootstrapFn();
      }
    }
  );
} else {
  bootstrapFn();
}

export default appBumperModule;
