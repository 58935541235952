import appBumperModule from "../appBumperModule";

function appBumper(appBumperConstants, $document) {
    "ngInject";

    function link(scope) {
        var textInputs = "input[type=text], input[type=password], input[type=textarea]";

        $document.on("focus.robloxAppBumper", textInputs, function () {
            scope.$apply(scope.closeBumper());
            $document.off("focus.robloxAppBumper", textInputs);
        });
    }

    return {
        restrict: "A",
        replace: true,
        scope: true,
        templateUrl: appBumperConstants.templates.appBumper, 
        link: link
    }
}

appBumperModule.directive("appBumper", appBumper);

export default appBumperModule;