import appBumperModule from "../appBumperModule";
import { addFooterClass, removeFooterClass } from "../utils/elementManipulor";

function appBumperController($log, $scope, $timeout, $window, appBumperConstants, appBumperService, urlService, languageResource, eventStreamService) {
    "ngInject";

    $scope.pageData = angular.extend({}, appBumperConstants.pageData);
    $scope.layout = angular.extend({}, appBumperConstants.layout);
    $scope.osTypes = appBumperConstants.osTypes;

    $scope.closeBumper = function() {
        $scope.layout.isVisible = false;
        appBumperService.logBumperClosed();
        removeFooterClass(appBumperConstants.pageElements.footerClass);
    }

    $scope.handleIosLink = function () {
        eventStreamService.sendEventWithTarget(
            "appBumperButtonClick",
            "click",
            {
                btn: "appBumper"
            }
        );

        if ($scope.pageData.osType === $scope.osTypes.ios) {
            //taken from dowloadTheapp.js, behavior from m.
            //should be replaced on v2 by proper iOS "intent" to open app.
            $timeout(function () {
                if ($scope.layout.hasFocus) {
                    $window.location.href = $scope.pageData.appDownloadLink;
                }
            }, 500);
        }
    }

    $scope.init = function init() {
        var data = appBumperService.getMetaData();
        $log.debug("bumper data", data);
        $scope.pageData.bumperDismissTimeMs = data.bumperDismissTimeMs;
        if (appBumperService.shouldShowBumper($scope.pageData.bumperDismissTimeMs)) {
            $scope.layout.appIconUrl = data.appIconUrl;
            $scope.pageData.appDownloadLink = data.appDownloadLink;
            $scope.pageData.osType = data.osType;
            $scope.pageData.linkToFollow = $scope.pageData.appDownloadLink;
            if (data.osType === $scope.osTypes.ios) {
                $scope.pageData.linkToFollow = appBumperConstants.iosUrl;
                $scope.pageData.textToShow = languageResource.get("Message.AppBumpIOSDevice");
            } else {
                $scope.pageData.textToShow = languageResource.get("Message.AppBumpAndroidDevice");
            }
            addFooterClass(appBumperConstants.pageElements.footerClass);
            $scope.layout.isVisible = true;
        }
    }

    $scope.init();
}

appBumperModule.controller("appBumperController", appBumperController);

export default appBumperController;